html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --theme-background-color: #e0ddd7;
  --theme-accent-primary-color: #bab1b0;
  --theme-accent-secondary-color: #7b7983;
  --theme-accent-primary2-color: #ede5d8;
  --theme-accent-secondary2-color: #f7f9f4;
  --theme-font-color: #282936;
  --theme-bg-color: #f6f6f6;
  --theme-color: #61045f;
  --box-shadow: 10px 10px 30px -10px #000;
  --box-shadow2: 0 1.4rem 8rem #0003;
}

body {
  color: var(--theme-font-color);
  box-sizing: border-box;
  background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/brillant.png");
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 10px;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.home .title-main, .home .send {
  background: #000;
}

.labelnone {
  display: none;
}

.motto {
  text-align: center;
  justify-content: center;
  line-height: 1.7em;
}

h1 {
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  margin: 0 0 .67em;
  font-size: 2em;
}

h4 {
  color: #000;
  letter-spacing: .17em;
  text-transform: uppercase;
  width: max-content;
  padding-left: 10px;
  font-size: 12px;
  line-height: 1.6em;
  position: absolute;
  bottom: 240px;
  right: 278px;
  transform: rotate(-90deg)translateY(-50%);
}

.firstprice {
  color: #000;
  font-weight: bold;
}

.topJMM {
  z-index: 10;
  text-transform: uppercase;
  transform-origin: 100% 100%;
  background: inherit;
  letter-spacing: .17em;
  border: none;
  padding: 20px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.6em;
  position: fixed;
  bottom: 120px;
  right: 20px;
  transform: rotate(-90deg)translateX(100%);
}

.shop .learn-more {
  width: 6.5rem;
}

.learn-more {
  cursor: pointer;
  vertical-align: middle;
  font-size: inherit;
  background: none;
  border: 2px solid #000;
  outline: none;
  width: 11rem;
  height: auto;
  padding: 0;
  font-family: inherit;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.learn-more .circle {
  background: #000;
  width: 2.5rem;
  height: 2rem;
  margin: 0;
  transition: all .45s cubic-bezier(.65, 0, .076, 1);
  display: block;
  position: relative;
}

.learn-more .circle .icon {
  background: #f5f5f5;
  margin: auto;
  transition: all .45s cubic-bezier(.65, 0, .076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
}

.learn-more .circle .icon.arrow {
  background: none;
  width: 1.125rem;
  height: .125rem;
  transition: all .45s cubic-bezier(.65, 0, .076, 1);
  left: .425rem;
}

.learn-more .circle .icon.arrow:before {
  content: "";
  border-top: .125rem solid #f5f5f5;
  border-right: .125rem solid #f5f5f5;
  width: .625rem;
  height: .625rem;
  position: absolute;
  top: -.3rem;
  right: .0625rem;
  transform: rotate(45deg);
}

.learn-more .button-text {
  color: #000;
  text-align: center;
  text-transform: uppercase;
  margin: -.15rem 0 0 2.5rem;
  padding: .5rem 0;
  font-size: .9rem;
  font-weight: 700;
  line-height: 1.6;
  transition: all .45s cubic-bezier(.65, 0, .076, 1);
  position: absolute;
  inset: 0;
}

.learn-more:hover .circle {
  width: 100%;
}

.learn-more:hover .circle .icon.arrow {
  background: #f5f5f5;
  transform: translate(1rem);
}

.learn-more:hover .button-text {
  color: #f5f5f5;
}

#btndiffrentcolor {
  background: var(--theme-gradient);
  color: #f5f5f5;
}

.learn-more.sold:hover .circle .icon.arrow {
  background: #f5f5f5;
  transform: translate(0);
}

.learn-more.sold:hover .button-text {
  color: #000;
}

.learn-more.sold:hover .circle {
  width: 40%;
}

#logo-j {
  color: #fff;
  background: #000;
}

.button2 {
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  background: #000;
  border: 0;
  border-radius: 2px;
  outline: 0;
  align-items: center;
  margin: auto;
  padding: .3rem .9rem .3rem .3rem;
  font-size: 17px;
  line-height: 1.5rem;
  text-decoration: none;
  display: flex;
}

.button2:before {
  content: "";
  background-color: #fff;
  width: 0;
  height: 1px;
  margin-right: 10px;
  transition: all .42s cubic-bezier(.25, .8, .25, 1);
  display: inline-block;
}

.button2:hover:before {
  background-color: #fff;
  width: 3rem;
}

header {
  background: var(--theme-bg-color);
  height: auto;
  display: flex;
}

.nav {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

@media only screen and (min-width: 2500px) {
  .nav {
    max-width: 1800px;
    margin: auto;
  }

  .hero {
    max-width: 1800px;
  }
}

.nav ul {
  justify-content: flex-end;
  width: 85%;
  margin-right: 30px;
  font-weight: 550;
  list-style-type: none;
  display: flex;
}

.nav ul li {
  margin: 5px 10px auto;
}

.nav ul li:nth-child(5) {
  padding: 0;
}

.nav ul a {
  color: var(--theme-font-color);
  text-transform: uppercase;
  font-size: calc(.5vw + 15px);
  text-decoration: none;
  list-style-type: none;
  position: relative;
}

.instaNav img {
  width: 25px;
  display: flex;
  position: relative;
}

.pine {
  background: #000;
}

.menu-items {
  color: #000;
  text-transform: uppercase;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 2.2em;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.nav ul a:hover {
  opacity: .7;
}

.nav ul a.current:after {
  content: "";
  background-color: var(--theme-color);
  justify-content: center;
  align-items: center;
  width: 95%;
  height: .13rem;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nav ul a:after {
  content: "";
  background-color: var(--theme-color);
  justify-content: center;
  align-items: center;
  width: 0%;
  height: .13rem;
  transition: all .3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.nav ul a:hover:after {
  width: 95%;
}

.nav ul a.instaNav:hover:after {
  width: 0%;
}

.burger {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  display: none;
}

.line {
  background: var(--theme-font-color);
  border-radius: .8rem;
  width: 3em;
  height: .3em;
  margin-top: .7em;
  transition: all .3s ease-in-out;
  display: block;
}

.logo-jmm {
  width: 200px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 5px 10px;
}

.menu-links {
  width: 30%;
  padding-bottom: 20px;
  display: block;
  position: fixed;
  bottom: 50px;
  right: -16px;
}

.hero {
  margin: auto;
}

.hero h1 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background: #00000080;
  margin-left: 15px;
  padding: 3px 5px;
  font-size: 55px;
  position: absolute;
  top: 97px;
}

.imghero {
  object-fit: cover;
  object-position: center;
  filter: brightness();
  width: 100%;
  height: 550px;
}

.hero .controls .next {
  display: flex;
}

.hero .controls .prev {
  justify-content: center;
  display: flex;
}

.tittle {
  text-align: center;
  margin: 30px auto;
  display: block;
}

.section-tittle {
  text-align: center;
  margin: 80px auto 100px;
  font-size: 34px;
  position: relative;
}

.title-main {
  color: #f5f5f5;
  background: #000;
  border-radius: 2px;
  margin: 0;
  padding: .4rem;
  font-weight: bold;
  display: inline;
}

.title-sub {
  color: var(--theme-font-color);
  font-size: 34px;
  font-weight: bold;
  line-height: 3rem;
}

.gallery-work {
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto auto 90px;
  display: flex;
}

.gallery-work.right {
  flex-direction: row-reverse;
  display: flex;
}

.gallery-work .gwork {
  border-radius: 0;
  width: 505px;
  height: 550px;
  transition: all 1s ease-in-out;
  box-shadow: inset 2px 2px 2px #ffffff80, 7px 7px 20px #0000001a, 4px 4px 5px #0000001a;
}

.gallery-work .gwork.pagehome {
  height: 630px;
}

.gallery-work .gwork img {
  object-fit: cover;
  object-position: 50% 40%;
  border-radius: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-shadow: 0 1.4rem 8rem #0003;
}

#down, #down2 {
  object-position: 50% 20%;
}

.gallery-work:not(.slideshow) .gwork:hover {
  transform: scale(1.2);
}

.gallery-work .slideshow {
  border: none;
  justify-content: center;
  width: 100vw;
  height: 100%;
  margin: auto auto 20px;
  display: flex;
}

.shop.gallery-work:not(.slideshow) img {
  border-radius: .8rem;
}

.gallery-work.slideshow .gwork {
  box-shadow: var(--box-shadow2);
  background-image: url("https://www.toptal.com/designers/subtlepatterns/uploads/brillant.png");
  height: 500px;
  transition: all 1s ease-in-out;
}

.shop .gscribe, .shop .gscribeR {
  width: 300px;
  padding: 20px;
}

.shop .gscribe p, .shop .gscribeR p {
  text-align: center;
  text-align: none;
  line-height: 1.5rem;
}

.shop.gscribe:not(.slideshow) p, .shop.gscribeR:not(.slideshow) p {
  text-align: center;
}

.shop.gscribe.scribecanvas p {
  text-align: center;
  line-height: 2rem;
}

.gallery-work .gwork .glide, .gallery-work .gwork .glide__slides, .gallery-work .gwork .glide__track {
  width: 100%;
  height: 100%;
}

.gallery-work .glide__arrow {
  border: none;
  top: auto;
  bottom: -1.7em;
}

.glide__arrows .glide__arrow {
  box-shadow: none;
}

.glide__arrows .arr {
  background: #f5f5f5;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: all .4s ease-in-out;
  display: flex;
}

.glide__arrows .arr:hover {
  box-shadow: 0 1.4rem 8rem #0003;
}

.glide__arrows .arr span {
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 9.6px;
  left: 9px;
  transform: rotate(45deg);
}

.glide__arrows .arr.left {
  transform: rotate(-90deg);
}

.glide__arrows .arr.right {
  transform: rotate(90deg);
}

.gworkSH {
  background-color: var(--theme-bg-color);
  padding: 40px;
}

.gworkSH img {
  width: 400px;
  height: 400px;
  padding: 0;
}

.mySlides {
  box-shadow: 10px 10px 30px -10px #000;
}

.gscribe {
  text-align: center;
  z-index: 1;
  background-color: var(--theme-bg-color);
  width: 450px;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: -50px;
  padding: 30px;
  display: block;
  box-shadow: 0 1.4rem 8rem #0003;
}

.gscribeR {
  text-align: center;
  background-color: var(--theme-bg-color);
  z-index: 1;
  width: 450px;
  height: -moz-fit-content;
  height: fit-content;
  margin-right: -50px;
  padding: 30px;
  display: block;
  box-shadow: 0 1.4rem 8rem #0003;
}

.gscribe p {
  text-align: justify;
  margin: 10px 0;
  padding: 5px;
  font-size: 17px;
  line-height: 1.5rem;
}

.linescribe {
  text-align: center;
  background-color: var(--theme-color);
  border-radius: .8rem;
  justify-content: center;
  align-items: center;
  width: 35%;
  height: 2px;
  display: flex;
  transform: translateX(90%);
}

.gscribeR p {
  text-align: justify;
  margin: 10px 0;
  padding: 5px;
  font-size: 17px;
  line-height: 1.5rem;
}

#MellowY {
  object-fit: fill;
  object-position: 50% 70%;
  box-shadow: 0 1.4rem 8rem #0003;
}

.gallery-container {
  justify-content: center;
  width: 100%;
  height: auto;
  margin-top: 100px;
  padding: 0;
  display: flex;
}

.container {
  height: auto;
  margin-bottom: 30px;
  padding: 0;
  display: block;
}

.container p {
  margin-left: 20px;
  display: flex;
}

.image img {
  background: none;
  width: 350px;
  height: auto;
  max-height: 50vh;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  display: block;
  box-shadow: 10px 10px 30px -10px #000;
}

a {
  color: var(--theme-font-color);
  font-size: 1rem;
  text-decoration: none;
  list-style-type: none;
}

.blog-post {
  background-color: #0000;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
  max-width: 1800px;
  margin: auto;
  padding: 50px;
  display: flex;
  box-shadow: 0 1.4rem 8rem #0003;
}

.blog-post_img {
  box-shadow: var(--box-shadow2);
  background: none;
  min-width: 27rem;
  padding: 0;
  position: relative;
}

.blog-spot_info {
  text-align: center;
  border-radius: .8rem;
  height: max-content;
  display: block;
}

.blog-post_img img {
  width: 100%;
  display: block;
}

.popular__shadow {
  background: linear-gradient(#0000 50%, #000 125%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.learn-more.sold .button-text {
  transition: all;
}

.blog-spot_info p {
  color: var(--theme-font-color);
  text-align: justify;
  justify-content: space-between;
  margin: 30px 0;
  padding: 0;
  font-size: 18px;
  line-height: 2rem;
}

.content-box {
  column-count: 2;
  gap: 0 50px;
}

.blog-spot_me span {
  color: var(--theme-font-color);
  margin: .5rem 0;
  display: block;
}

.blog-post_tittle {
  text-transform: uppercase;
  color: var(--theme-font-color);
  margin: 1.5rem 0 2rem;
  font-size: 2.5rem;
}

@media (max-width: 880px) {
  .blog-post {
    flex-direction: column;
  }

  .blog-post_img {
    min-width: auto;
    max-width: 100%;
    margin-top: -5rem;
  }
}

@media (max-width: 768px) {
  .blog-post {
    padding: 1.5rem;
  }

  .blog-spot_info p {
    margin: 20px 0;
    line-height: 1.5rem;
  }

  h1 {
    margin: 0 0 .67em !important;
  }

  .lnr {
    display: none;
  }
}

@media (max-width: 1068px) {
  .content-box {
    column-count: 1;
  }
}

@media (max-width: 880px) {
  .content-box {
    column-count: 1;
  }
}

.top {
  z-index: 1000000000;
  text-transform: uppercase;
  transform-origin: 100% 100%;
  background: inherit;
  cursor: pointer;
  border: none;
  padding: 20px;
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  transition: all 1s ease-in-out;
  display: none;
  position: fixed;
  bottom: 0;
  right: 20px;
  transform: rotate(-90deg)translateX(100%);
}

.top.show {
  display: block;
}

.top:after {
  content: "";
  vertical-align: 3px;
  transform-origin: 0 0;
  background: #000;
  width: 30px;
  height: 2px;
  margin-left: 15px;
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
}

.top:hover:after {
  transition: all .3s cubic-bezier(.785, .135, .15, .86);
  transform: scaleX(1.8);
}

.containerform {
  width: 100%;
  height: 100%;
  margin: 30px 0;
  padding: 20px 100px;
  display: flex;
  position: relative;
}

.containerform:after {
  content: "";
  z-index: -1;
  filter: blur(50px);
  background: url("joanna.84fa97f2.webp") 0 0 / cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gallery-work.containerform .gwork {
  width: 500px;
  height: 600px;
}

.gallery-work.containerform .gwork img {
  width: 180% !important;
}

.gallery-work.containerform .gwork:hover {
  transform: none;
}

.gallery-work.containerform .gscribe {
  width: auto;
  height: 100%;
  padding: 0;
}

.gallery-work.containerform .contactbox {
  text-align: center;
  background: var(--theme-bg-color);
  justify-content: center;
  align-items: center;
  display: block;
  box-shadow: 0 1.4rem 8rem #0003;
}

.contacktright {
  width: 500px;
  padding: 30px;
  position: relative;
}

.contus {
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 10px;
  position: relative;
}

.contus:after {
  content: "";
  background-color: var(--theme-color);
  width: 100px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.field {
  background-color: #7b79831a;
  border: 2px solid #0000;
  outline: none;
  width: 100%;
  height: 100%;
  margin-bottom: 22px;
  padding: .5rem 1rem;
  font-size: 14px;
  transition: all .3s;
}

.field:hover {
  background-color: #0000001a;
}

.field:focus {
  border: 2px solid var(--theme-accent-secondary-color);
  background-color: #fff;
}

.textarea {
  height: 600px;
  min-height: 150px;
}

.send {
  color: #f5f5f5;
  text-align: center;
  --box-shadow2: 0 1.4rem 8rem #0003;
  cursor: pointer;
  background: #000;
  border: none;
  border-radius: 2px;
  justify-content: center;
  margin: auto;
  padding: .5em 1em .5em .9em;
  font-family: inherit;
  font-size: 1.1rem;
  transition: all .2s;
  display: flex;
  overflow: hidden;
}

.send.gallery {
  font-size: .8rem;
  transition: none;
  animation: none;
  transform: none;
}

.send .text {
  margin: 2px;
  transition: all .3s ease-in-out;
  display: block;
}

.send svg {
  transform-origin: center;
  transition: transform .3s ease-in-out;
  display: block;
}

.svg-wrapper, .svg-wrapper-1 {
  display: block;
}

.send:hover .svg-wrapper {
  animation: .6s ease-in-out infinite alternate fly-1;
}

.send:hover svg {
  transform: translateX(1.2em)rotate(45deg)scale(1.1);
}

.send:hover .text {
  transform: translateX(5em);
}

.send:active {
  transform: scale(.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(.1em);
  }

  to {
    transform: translateY(-.1em);
  }
}

@media (max-width: 1068px) {
  .accordion-container {
    width: min(100% - 30px, 854px);
    margin-top: 30px !important;
  }

  h3 {
    margin-left: 0 !important;
    font-size: 17px !important;
  }

  .panel {
    margin-left: 0;
    width: 100% !important;
    padding: 0 !important;
  }

  .accordion:before {
    margin-right: 0 !important;
  }
}

.accordion-container {
  flex-direction: column;
  justify-content: space-evenly;
  width: min(100% - 30px, 984px);
  margin: 70px auto auto;
  display: flex;
}

.accordion, .fluidwrap {
  color: #202f46;
  cursor: pointer;
  text-align: left;
  background-color: #0000;
  border: none;
  outline: none;
  margin-bottom: 15px;
  transition: all .4s;
}

.accordion h3, .fluidwrap {
  text-transform: uppercase;
  margin-left: 25px;
  font-size: 19px;
}

.delivery {
  line-height: 1.4em;
}

.active, .accordion:hover, .fluidwrap:hover, .panelfluid p:hover {
  color: var(--theme-color);
}

.panel {
  background-color: #fff;
  max-height: 0;
  margin-left: 26px;
  transition: max-height .2s ease-out;
  overflow: hidden;
}

.panel p {
  padding-top: 1px;
  padding-bottom: 20px;
  font-size: 16px;
}

.accordion:before {
  content: "+";
  color: var(--theme-font-color);
  float: right;
  margin-right: 15px;
  font-size: 18px;
  font-weight: 500;
}

.accordion.active:before {
  content: "−";
  color: var(--theme-font-color);
}

.fluid {
  background-color: #0000;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  display: inline;
  box-shadow: 0 1.4rem 8rem #0003;
}

.panelfluid {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.panelfluid ul {
  list-style-type: none;
  display: flex;
}

.panelfluid ul li {
  border-right: 1px solid #000;
  margin: 5px 0;
}

.panelfluid ul li:last-child {
  border-right: none;
}

.panelfluid p {
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 1px;
  font-size: 15px;
}

.socialfoot {
  margin-top: 20px;
}

.fsoc {
  margin: 15px 20px 0;
  display: inline-flex;
}

footer {
  text-align: center;
  background-color: var(--theme-bg-color);
  color: var(--theme-font-color);
  margin-top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
}

.footfoter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.instagram {
  justify-content: center;
  width: auto;
}

.mail {
  text-align: center;
  justify-content: center;
  margin: 10px 0;
  display: flex;
}

.instafoot-mail {
  width: 35px;
  height: 41px;
  margin-bottom: -6px;
  margin-left: 18px;
}

.gmail {
  text-align: center;
  justify-content: center;
  margin-top: 7px;
  margin-left: 20px;
  display: block;
}

.instafoot {
  width: 30px;
  height: 30px;
  margin-top: 15px;
  margin-left: 18px;
  display: inline-block;
}

.instafoot-pinetr {
  width: 30px;
  margin-right: -10px;
}

.instafoot-mail:hover, .instafoot:hover {
  opacity: .7;
}

.logo-footjmm {
  width: 100px;
  margin-left: 20px;
}

.footblock {
  margin: 0 auto auto;
}

.wrapwrap {
  justify-content: center;
  height: 100%;
  margin-top: 40px;
  padding: 0 220px;
  display: flex;
}

.clumsy {
  padding: 100px 50px 20px 100px;
  display: flex;
}

.clumsy img {
  width: 500px;
  height: 500px;
}

.notfound {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding-right: 100px;
  display: flex;
}

.notfoundtext {
  color: #61045f;
  font-size: 37px;
  font-weight: 900;
}

.notfound .worries {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 400;
}

.notfound p {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
}

.star p {
  font-size: 16px;
}

.star {
  background-color: none;
  position: absolute;
  bottom: 15px;
  left: 30px;
}

.notfound a {
  color: #61045f;
  font-size: 20px;
  font-weight: bold;
}

.notfound a:hover {
  opacity: .8;
}

.page404 .learn-more {
  border-radius: 2px;
  margin: 30px auto;
}

.page404 .learn-more:hover {
  opacity: 1;
}

@media only screen and (max-width: 1300px) {
  .wrapwrap {
    margin-top: 40px;
    padding: 0;
  }

  .clumsy {
    padding: 58px 50px 20px;
  }

  .clumsy img {
    width: 400px;
    height: 400px;
  }
}

@media only screen and (max-width: 300px) {
  .clumsy img {
    height: 25vh;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .wrapwrap {
    margin-top: 10px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .logo-jmm {
    margin-bottom: 0;
  }

  .hero h1 {
    top: 112px;
  }

  .wrapwrap {
    justify-content: initial;
    flex-direction: column;
    margin-top: 40px;
  }

  .notfound {
    text-align: center;
    gap: 5px;
    margin: 0 auto;
    padding: 0 50px;
  }

  .clumsy {
    margin: 0 auto;
    padding: 0;
  }

  .clumsy img {
    width: 450px;
    height: 400px;
    margin: 0 auto;
  }

  .notfound p, .notfound a {
    font-size: 18px;
  }

  .notfound .worries {
    font-size: 15px;
  }

  .star p {
    font-size: 14px;
  }

  .notfoundtext {
    font-size: 28px;
  }

  .star {
    padding: 0 5px;
    left: 15px;
  }

  .page404 .learn-more {
    width: 7.4rem;
    margin: 40px auto 20px;
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .wrapwrap {
    padding: 0;
  }

  .clumsy {
    margin: 0 auto;
  }

  .clumsy img {
    width: 80vw;
    height: 30vh;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .notfound .worries {
    font-size: 11px;
  }

  .notfound {
    gap: 0;
  }

  .notfound p, .notfound a {
    font-size: 13px;
  }

  .star {
    left: 5px;
  }

  .star p {
    font-size: 10px;
  }

  .page404 .notfoundtext {
    font-size: 25px;
    margin: 0 0 1em !important;
  }

  .page404 .learn-more {
    width: 6.5rem;
    margin: 30px auto 20px;
  }

  .page404 .learn-more .circle {
    width: 1.8rem;
    height: 1.7rem;
  }

  .page404 .learn-more .circle .icon.arrow {
    width: .7rem;
    left: .37rem;
  }

  .page404 .learn-more .button-text {
    margin: -.3rem 0 0 1.85rem;
  }
}

.close {
  z-index: 1;
  background: none;
  border: none;
  padding: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.close:hover:before {
  opacity: 1;
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22%230f0f0f%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20transform%3D%22translate%28-290.000000,%20-65.000000%29%22%20fill-rule%3D%22nonzero%22%3E%3Cg%20transform%3D%22translate%28290.000000,%2065.000000%29%22%3E%3Cpath%20d%3D%22M22.5%201.5v21H1.5V1.5h21z%22%20stroke%3D%22%230f0f0f%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M16.999954%208.44117617%2015.5588332%207.00005699%2011.998935%2010.5575%208.44121825%207%207.00004599%208.44117067%2010.5585606%2011.9999973%207%2015.5587778%208.44122375%2017%2011.998935%2013.4414323%2015.5588277%2016.999943%2017%2015.5587723%2013.4414339%2011.9999973z%22/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  transform: none;
}

.close:before {
  opacity: .3;
  transform-origin: center;
  content: "";
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20transform%3D%22translate%28-290.000000,%20-65.000000%29%22%20fill-rule%3D%22nonzero%22%3E%3Cg%20transform%3D%22translate%28290.000000,%2065.000000%29%22%3E%3Cpath%20d%3D%22M22.5%201.5v21H1.5V1.5h21z%22%20stroke%3D%22%230f0f0f%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%230f0f0f%22%20d%3D%22M16.999954%208.44117617%2015.5588332%207.00005699%2011.998935%2010.5575%208.44121825%207%207.00004599%208.44117067%2010.5585606%2011.9999973%207%2015.5587778%208.44122375%2017%2011.998935%2013.4414323%2015.5588277%2016.999943%2017%2015.5587723%2013.4414339%2011.9999973z%22/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  transition: transform .3s, opacity .2s;
  display: block;
}

.no-fields .form {
  margin-top: 8px;
}

.form {
  flex-flow: column;
  display: flex;
}

.cookies-banner {
  z-index: 20000000;
  width: 400px;
  margin: 0 auto;
  font-size: 16px;
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
}

.description {
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
  margin: 0 0 24px;
}

.description p {
  color: #000;
  font-weight: bolder;
  display: inline-block;
}

.content {
  flex-direction: column;
  display: flex;
  position: relative;
}

.button.accept {
  background-color: #fff;
  border: 3px solid #000;
}

.button.accept:hover {
  color: #f5f5f5;
  background-color: #000;
}

.buttonscookie {
  flex-flow: column;
  margin: -8px;
  display: flex;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.buttonscookie .button {
  background: #fff;
  border-radius: 0;
  margin: 8px;
  padding: 11px 32px;
  font-weight: 900;
  line-height: 1.55556;
}

.button > span {
  word-wrap: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.button > * {
  min-width: 0;
}

.contenttitle {
  text-transform: none;
}

.contenttitle a, .description a {
  vertical-align: baseline;
  font-size: inherit;
  text-decoration: underline;
}

.description a:hover {
  color: #61045f;
}

.content .no-fields p {
  display: block;
}

.containercookies {
  box-sizing: border-box;
  color: #0f0f0f;
  background-color: #fff;
  background-image: url("Cookie3.cbefcae5.svg");
  background-position: right 70px bottom 94px;
  border-radius: 0;
  padding: 70px 48px 48px;
  font-size: 18px;
  line-height: 1.55556;
  position: relative;
  overflow: visible;
  box-shadow: 0 11px 32px -5px #363d4d26;
}

.narrow-container {
  width: 80%;
  margin-left: 40px;
}

.breadcrumbs {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-size: 1.2rem;
  display: block;
}

.list_bread {
  vertical-align: middle;
  width: 100%;
  display: inline-block;
}

.breadcrumbs ol {
  white-space: normal;
  font-size: 1rem;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.breadcrumbs li:before {
  content: "";
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  padding: 0 7px;
  font-family: FontAwesome;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
}

.breadcrumbs li:first-child:before {
  display: none;
}

.breadcrumbs ol li a:hover {
  color: #61045f;
}

.subcm {
  text-transform: uppercase;
  font-size: 19px;
}

.subsub {
  padding: 10px 0;
  font-size: 15px;
  line-height: 1.3rem;
}

.textnotice {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.textnotice h2 {
  font-size: 30px;
}

.provisions {
  margin-bottom: 50px;
}

@media only screen and (min-width: 960px) {
  .cookies-banner {
    margin: 0;
    bottom: 32px;
    left: auto;
    right: 90px;
  }

  .containercookies {
    box-shadow: 0 10px 24px #363d4d26;
  }
}

@media only screen and (max-width: 1000px) {
  html.menu-open {
    overflow-y: hidden;
  }

  #hamburger-menu {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: hidden;
  }

  #hamburger-menu.open {
    display: block;
  }

  #hamburger-menu.active {
    display: flex;
  }

  .burger {
    margin-bottom: 4px;
    margin-right: 20px;
    transition: all .3s ease-in-out;
    display: block;
  }

  .burger.active .line:first-child {
    transform: translateY(-300px);
  }

  .burger.active .line:nth-child(2) {
    transform: rotate(-405deg)translateY(7.5px);
  }

  .burger.active .line:nth-child(3) {
    transform: rotate(-315deg)translateY(-7.5px);
  }

  .burger.active {
    z-index: 999999;
    margin-bottom: 16px;
    position: fixed;
    right: 10px;
  }

  .instaNav img {
    margin: auto;
  }

  .nav ul {
    z-index: 9999;
    text-align: center;
    background: #f6f6f6;
    flex-direction: column;
    justify-content: flex-start;
    gap: 50px;
    width: 100%;
    height: 92vh;
    margin-top: -1px;
    margin-right: 0;
    transition: all .3s ease-in-out;
    position: absolute;
    top: 8vh;
    right: 0;
    transform: translate(100%);
  }

  .nav ul li {
    margin: 0;
    padding: 0;
  }

  .nav ul.active {
    display: flex;
    transform: translate(0%);
  }

  .nav ul li:first-child {
    margin-top: 120px;
  }

  .nav a {
    letter-spacing: 2px;
    font-size: 2.5rem;
    font-weight: bold;
  }
}

@media only screen and (max-width: 768px) {
  ul.nav, .nav {
    width: 100%;
  }

  .cookies-banner {
    font-size: 14px;
  }

  .containercookies {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 1000px) {
  .narrow-container {
    width: 95%;
    margin-left: 10px;
  }

  .hero {
    margin-bottom: 50px;
  }

  .topJMM, .top.show {
    display: none;
  }

  .section-tittle {
    text-align: center;
    margin: 80px 30px 100px 20px;
  }

  .panel {
    margin-left: 0;
  }

  .gallery-work {
    flex-flow: column;
    width: 100%;
    height: min-content;
    margin-bottom: 40px;
  }

  .gallery-work .gscribe {
    text-align: center;
    background-color: var(--theme-bg-color);
    justify-content: center;
    width: 100%;
    margin: auto;
    padding: 15px;
    display: block;
  }

  .gallery-work.containerform .gscribe {
    text-align: center;
    justify-content: center;
    width: 100%;
    margin: 10px auto;
    display: block;
  }

  .gallery-work .gscribeR {
    text-align: center;
    background-color: var(--theme-bg-color);
    justify-content: center;
    width: 100%;
    height: max-content;
    margin: auto;
    padding: 15px;
    display: block;
  }

  .gallery-work .gwork {
    text-align: center;
    background-color: var(--theme-bg-color);
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
  }

  .gallery-work.containerform {
    padding: 0;
    display: flex;
  }

  .gallery-work.containerform .gwork {
    text-align: center;
    background-color: var(--theme-bg-color);
    justify-content: center;
    width: 100%;
    height: max-content;
    margin: 10px;
    display: flex;
  }

  .gallery-work.containerform .gwork img {
    opacity: .8;
    width: 100%;
    height: 400px;
  }

  .contacktright, .gallery-work .gwork img {
    width: 100%;
  }

  .gallery-work:not(.gowrk) .gwork:hover {
    transform: none;
  }

  .gallery-work.right {
    flex-direction: column;
    margin-bottom: 40px;
    margin-left: 0;
  }

  .gallery-work.containerform .gwork img, .glide__arrows, .top, .top:after {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .hero h1 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .title-main {
    padding: 0 10px;
  }

  .title-sub {
    padding: 0;
  }

  .gallery-work .gwork img {
    width: 100%;
  }

  .shop main {
    margin-top: 50px;
  }

  .shop.gallery-work.slideshow .gscribe, .shop.gallery-work.slideshow .gscribeR {
    width: 100% !important;
  }

  .gallery-work .gscribe, .gallery-work .gscribeR {
    width: 100%;
  }

  .goshop {
    width: 20vw;
    font-size: 2.5vw;
  }

  .goshop:before {
    width: 6vw;
    height: 3vh;
    top: calc(100% - 2.4vh);
    left: calc(100% - 3.8vw);
  }

  .gallery-work.containerform .gwork img {
    display: none;
  }

  .gallery-work.containerform .gscribe {
    width: 100%;
    left: 0;
  }

  .blog-post {
    width: 100%;
    margin-top: 80px;
  }

  .blog-post_img {
    width: 100%;
  }

  .containerform:after {
    display: none;
  }

  .containerform {
    width: 100%;
    height: 100%;
    margin: 30px 0;
    padding: 20px 0;
    display: flex;
    position: static;
  }

  .gallery-work.containerform .gwork {
    width: 100%;
  }

  .gallery-work .gwork {
    box-shadow: none;
  }
}

#cadmium_green {
  object-position: 0% 0%;
}

.meme {
  margin-top: 20px;
  margin-left: 30px;
}

.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 .25em .5em #0000001a;
  opacity: 1;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ffffff80;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: #fff;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: .33;
}

.glide__bullets {
  z-index: 2;
  list-style: none;
  display: inline-flex;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.glide__bullet {
  cursor: pointer;
  background-color: #ffffff80;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 .25em;
  padding: 0;
  line-height: 0;
  transition: all .3s ease-in-out;
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  background-color: #ffffff80;
  border: 2px solid #fff;
}

.glide__bullet--active {
  background-color: #fff;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}



/*# sourceMappingURL=index.49d258ef.css.map */
