* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


:root {
  --theme-background-color: #e0ddd7;
  --theme-accent-primary-color: #bab1b0;
  --theme-accent-secondary-color: #7b7983;
  --theme-accent-primary2-color: #ede5d8;
  --theme-accent-secondary2-color: #f7f9f4;
  --theme-font-color: #282936;
  --theme-bg-color: #f6f6f6;

  --theme-color: #61045F;
  --box-shadow: 10px 10px 30px -10px #000;
  --box-shadow2: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
}


body {
  background-image: url(https://www.toptal.com/designers/subtlepatterns/uploads/brillant.png);
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  color: var(--theme-font-color);
  font-size: 10px;
  box-sizing: border-box;
  max-width: 100%;
  /* height: 100vh; */
}

html {

  overflow-x: hidden;
  scroll-behavior: smooth;

}

.home .title-main,
/* .home .section-tittle::after, */
.home .send {
  background: black;
}


.labelnone {
  display: none
}




.motto {
  justify-content: center;
  text-align: center;
  line-height: 1.7em;
}

h1 {
  text-transform: uppercase;

}

h2 {
  font-size: 2em;
  /* margin: 0.67em 0; */
  text-transform: uppercase;
  margin-top: 0em;
  margin-right: 0px;
  margin-bottom: 0.67em;
  margin-left: 0px;
}



h4 {

  width: max-content;
  position: absolute;
  color: #000;
  right: 278px;
  bottom: 240px;
  padding-left: 10px;
  transform: rotate(-90deg) translateY(-50%);
  letter-spacing: .17em;
  font-size: 12px;
  line-height: 1.6em;
  text-transform: uppercase;

}



.firstprice {

  color: black;
  font-weight: bold;

}

.topJMM {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.6em;
  padding: 20px;
  position: fixed;
  bottom: 120px;
  right: 20px;
  z-index: 10;
  text-transform: uppercase;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: bottom right;
  background: inherit;
  border: none;
  letter-spacing: .17em;
}


.shop .learn-more {
  width: 6.5rem;

}

.learn-more {

  width: 11rem;
  height: auto;
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 2px solid black;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;

}

.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2rem;
  background: black;


}

.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: whitesmoke;
}

.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.425rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.30rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid whitesmoke;
  border-right: 0.125rem solid whitesmoke;
  transform: rotate(45deg);
}

.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.5rem 0;
  margin: -0.15rem 0 0 2.5rem;
  color: black;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.learn-more:hover .circle {
  width: 100%;
}



.learn-more:hover .circle .icon.arrow {
  background: whitesmoke;
  transform: translate(1rem, 0);
}

.learn-more:hover .button-text {
  color: whitesmoke;
}



#btndiffrentcolor {
  background: var(--theme-gradient);
  /* width: 6.5rem; */
  color: whitesmoke;

}

.learn-more.sold:hover .circle .icon.arrow {
  background: whitesmoke;
  transform: translate(0, 0);
}

.learn-more.sold:hover .button-text {
  color: black;
}

.learn-more.sold:hover .circle {
  width: 40%;
}


/*  */

#logo-j {
  background: black;
  color: white;
}

#logo-jmm-box {
  /* fill: red !important; */
  /* animation-name: logo-color-rainbow; */
  /* animation-duration: 4s; */
}



/*   */
.button2 {
  margin: auto;
  align-items: center;
  background: black;
  color: #fff;
  cursor: pointer;
  display: flex;
  border-radius: 2px;
  font-size: 17px;
  /* font-weight: 700; */
  line-height: 1.5rem;
  text-decoration: none;
  text-transform: uppercase;
  outline: 0;
  border: 0;
  padding: 0.3rem 0.9rem 0.3rem 0.3rem;
}

.button2:before {
  background-color: #fff;
  content: "";
  display: inline-block;
  height: 1px;
  margin-right: 10px;
  transition: all .42s cubic-bezier(.25, .8, .25, 1);
  width: 0;
}

.button2:hover:before {
  background-color: #fff;
  width: 3rem;
}

header {
  display: flex;
  background: var(--theme-bg-color);
  height: auto;
}


.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

}


@media only screen and (min-width: 2500px) {

  .nav {

    max-width: 1800px;
    margin: auto;

  }

  .hero {
    max-width: 1800px;
  }

}


.nav ul {

  list-style-type: none;
  display: flex;
  width: 85%;
  justify-content: flex-end;
  font-weight: 550;
  margin-right: 30px;


}


.nav ul li {
  margin: auto 10px;
  margin-top: 5px;

}

.nav ul li:nth-child(5) {
  padding: 0 0;

}



.nav ul a {

  font-size: calc(0.5vw + 15px);

  list-style-type: none;
  text-decoration: none;
  color: var(--theme-font-color);
  text-transform: uppercase;
  position: relative;


}


.nav ul a.current {}



.instaNav img {
  display: flex;
  position: relative;

  width: 25px;

}

.pine {
  background: #000;
}

.menu-items {

  position: relative;
  color: black;
  text-decoration: none;
  font-size: 2.2em;
  text-transform: uppercase;
  font-weight: 400;
  display: inline-block;
  width: fit-content;


}



.nav ul a:hover {
  opacity: 0.7;
}


.nav ul a.current::after {
  content: '';
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0px;
  width: 95%;
  background-color: var(--theme-color);
  height: 0.13rem;
  transition: .3s ease-in-out;
}

.nav ul a::after {
  content: '';
  position: absolute;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 0px;
  width: 0%;
  background-color: var(--theme-color);
  height: 0.13rem;
  transition: .3s ease-in-out;
}



.nav ul a:hover::after {

  width: 95%;

}

.nav ul a.instaNav:hover::after {
  width: 0%;
}



.burger {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: none;

}




.line {
  display: block;
  height: .3em;
  width: 3em;
  background: var(--theme-font-color);

  border-radius: 0.8rem;
  transition: .3s ease-in-out;
  margin-top: .7em;
}





.logo-jmm {
  width: 200px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
  height: auto;
}



.menu-links {
  display: block;
  position: fixed;
  right: -16px;
  bottom: 50px;
  padding-bottom: 20px;
  width: 30%;



}

.hero {
 
  margin: auto;
}


.hero h1 {
  position: absolute;
  margin-left: 15px;
  top: 97px;
  padding: 3px 5px;
  color: #fff;
  text-align: center;
  font-size: 55px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);
}

.imghero {
  width: 100%;
  height: 550px;
  object-fit: cover;
  object-position: center;
  filter: brightness(1);

}



.hero .controls .next {
  display: flex;

}

.hero .controls .prev {
  display: flex;
  justify-content: center;

}




.tittle {
  display: block;
  text-align: center;
  margin: 30px auto;


}





.section-tittle {
  
  font-size: 34px;

  position: relative;
  text-align: center;
  margin: 80px auto 100px auto;

}

.title-main {
  margin: 0px;
  padding: 0.4rem;
  border-radius: 2px;
 
  font-weight: bold;
  color: whitesmoke;
  background: black;
  display: inline;
}

.title-sub {
  font-size: 34px;
  color: var(--theme-font-color);
  font-weight: bold;
  line-height: 3rem;
}


.gallery-work {
  display: flex;

  width: 100%;
  height: 100%;
  justify-content: center;
  border: none;
  margin: auto;
  margin-bottom: 90px;
  align-items: center;
}

.gallery-work.right {
  display: flex;
  flex-direction: row-reverse;

}



.gallery-work .gwork {

  width: 505px;
  height: 550px;
  transition: 1s ease-in-out;
  border-radius: 0rem;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
}

.gallery-work .gwork.pagehome {
  height: 630px;
}

.gallery-work .gwork img {
  object-fit: cover;
  object-position: 50% 40%;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 0rem;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
}


#down,
#down2 {
  object-position: 50% 20%;

}

.gallery-work.right .gwork {}



.gallery-work:not(.slideshow) .gwork:hover {
  transform: scale(1.2);

}

.gallery-work .slideshow {
  display: flex;
  width: 100vw;
  height: 100%;
  justify-content: center;
  border: none;
  margin: auto;
  margin-bottom: 20px;

}

.shop.gallery-work:not(.slideshow) img {
  border-radius: 0.8rem;
}

.gallery-work.slideshow .gwork {
  box-shadow: var(--box-shadow2);
  height: 500px;
  background-image: url(https://www.toptal.com/designers/subtlepatterns/uploads/brillant.png);
  transition: 1s ease-in-out;


}

.shop .gscribe {
  padding: 20px;
  width: 300px;
}

.shop .gscribeR {

  padding: 20px;
  width: 300px;
}



.shop .gscribe p,
.shop .gscribeR p {

  text-align: center;
  line-height: 1.5rem;
  text-align: none;
}


.shop.gscribe:not(.slideshow) p,
.shop.gscribeR:not(.slideshow) p {
  text-align: center;

}


.shop.gscribe.scribecanvas p {
  line-height: 2rem;
  text-align: center;
}





.gallery-work .gwork .glide,
.gallery-work .gwork .glide__slides,
.gallery-work .gwork .glide__track {
  width: 100%;
  height: 100%;


}

.gallery-work .glide__arrow {
  top: auto;
  bottom: -1.6999999999999993em;
  border: none;

}


.glide__arrows .glide__arrow {
  box-shadow: none;
}



.glide__arrows .arr {
  display: flex;
  background: whitesmoke;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: 0.4s ease-in-out;

}

.glide__arrows .arr:hover {
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
}

.glide__arrows .arr span {
  position: absolute;
  height: 8px;
  width: 8px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(45deg);
  left: 9px;
  top: 9.6px;
}

.glide__arrows .arr.left {
  transform: rotate(-90deg);

}

.glide__arrows .arr.right {
  transform: rotate(90deg);
}



.gworkSH {
  background-color: var(--theme-bg-color);
  padding: 40px;
}

.gworkSH img {

  padding: 0px;
  width: 400px;
  height: 400px;
}



.mySlides {
  box-shadow: 10px 10px 30px -10px #000;
}


.gscribe {
  display: block;
  text-align: center;
  z-index: 1;
  height: fit-content;
  width: 450px;
  background-color: var(--theme-bg-color);
  margin-left: -50px;
  padding: 30px;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
}

.gscribeR {
  display: block;
  text-align: center;
  height: fit-content;
  width: 450px;
  background-color: var(--theme-bg-color);
  padding: 30px;
  z-index: 1;
  margin-right: -50px;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);

}


.gscribe p {

  font-size: 17px;
  line-height: 1.5rem;
  margin: 10px 0;
  padding: 5px;
  text-align: justify;
}


.linescribe {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 35%;
  height: 2px;
  background-color: var(--theme-color);
  /* #61045F; */
  align-items: center;
  border-radius: 0.8rem;
  transform: translateX(90%);

}




.gscribeR p {

  font-size: 17px;
  line-height: 1.5rem;
  margin: 10px 0;
  padding: 5px;
  text-align: justify;
}


#MellowY {
  object-fit: fill;
  object-position: 50% 70%;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
}




.gallery-container {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  padding: 0px;
  
  width: 100%;
  height: auto;


}


.container {

  display: block;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  padding: 0px;
  height: auto;
}

.container p {
  display: flex;
  margin-left: 20px;

}




.image img {


  display: block;
  max-height: 50vh;
  width: 350px;
  height: auto;
  box-shadow: 10px 10px 30px -10px #000;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 50px;
  padding: 0px;
  background: transparent;


}


a {
  list-style-type: none;
  text-decoration: none;
  color: var(--theme-font-color);
  font-size: 1.0rem;

}




.blog-post {

  display: flex;
  gap: 50px;
  margin-top: 120px;
  justify-content: space-around;
  align-items: center;
  max-width: 1800px;
  margin: auto;
  padding: 50px;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
  background-color: transparent;

}

.blog-post_img {
  min-width: 27rem;
  padding: 0px;
  background: transparent;
  box-shadow: var(--box-shadow2);
  position: relative;
}


.blog-spot_info {
  display: block;
  text-align: center;
  height: max-content;
  border-radius: 0.8rem;
}


.blog-post_img img {
  display: block;
  width: 100%;
 


}

.popular__shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, hsla(0, 0%, 0%, 0) 50%, hsl(0, 0%, 0%)125%);
}





.learn-more.sold .circle {}

.learn-more.sold .button-text {
  transition: 0s;
}

.blog-spot_info p {
  padding: 0;
  margin: 30px 0;
  line-height: 2rem;
  font-size: 18px;
  color: var(--theme-font-color);
  text-align: justify;
  justify-content: space-between;
}

.content-box {
  column-count: 2;
  gap: 0 50px;
}

.blog-spot_me span {
  display: block;
  color: var(--theme-font-color);
  margin: 0.5rem 0;

}

.blog-post_tittle {

  font-size: 2.5rem;
  margin: 1.5rem 0 2rem;
  text-transform: uppercase;
  color: var(--theme-font-color);

}

@media (max-width: 880px) {
  .blog-post {
    flex-direction: column;
  }

  .blog-post_img {
    min-width: auto;
    max-width: 100%;
    margin-top: -5rem;
  }
}

@media (max-width: 768px) {
  .blog-post {
    padding: 1.5rem;
  }

  .blog-spot_info p {
    margin: 20px 0;
    line-height: 1.5rem;
  }


  h1 {
    margin: 0 0 0.67em 0 !important;
  }


  .lnr {
    display: none;
  }


}




@media (max-width: 1068px) {
  .content-box {
    column-count: 1;
  }


}





@media (max-width: 880px) {
  .content-box {
    column-count: 1;

  }

}



.top {
  display: none;
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 20px;
  z-index: 1000000000;
  text-transform: uppercase;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: bottom right;
  background: inherit;
  border: none;
  cursor: pointer;
  transition: all 1s ease-in-out;

}

.top.show {
  display: block;


}

.top::after {
  content: '';
  background: black;
  display: inline-block;
  margin-left: 15px;
  position: relative;
  top: 0;
  left: 0;
  vertical-align: 3px;
  width: 30px;
  height: 2px;
  transform-origin: left top;



}

.top:hover::after {

  transform: scaleX(1.8);
  transition: 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);

}




.containerform {
  margin: 30px 0px;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px 100px;

}


.containerform:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../image/joanna.webp);
  background-size: cover;
  z-index: -1;
  filter: blur(50px);

}



.gallery-work.containerform .gwork {
  width: 500px;
  height: 600px;
}


.gallery-work.containerform .gwork img {

  width: 180% !important;

}


.gallery-work.containerform .gwork:hover {

  transform: none;
}


.gallery-work.containerform .gscribe {
  height: 100%;
  width: auto;
  padding: 0;

}

.gallery-work.containerform .contactbox {

  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--theme-bg-color);
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);

}


.contacktright {
  position: relative;
  padding: 30px 30px;
  width: 500px;
}


.contus {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  text-transform: uppercase;


}

.contus:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  height: 2px;
  width: 100px;
  background-color: var(--theme-color);
}


.field {

  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0);
  background-color: rgba(123, 121, 131, 0.1);
  font-size: 1.1rem;
  margin-bottom: 22px;
  transition: 0.3s;
  font-size: 14px;


}

.field:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.field:focus {
  background-color: #fff;
  border: 2px solid var(--theme-accent-secondary-color);
}

.textarea {
  height: 600px;
  min-height: 150px;


}



.send {
  font-family: inherit;
  font-size: 1.1rem;
  background: black;
  color: whitesmoke;
  justify-content: center;
  padding: 0.5em 1em;
  padding-left: 0.9em;
  display: flex;
  text-align: center;
  border: none;
  border-radius: 2px;
  overflow: hidden;
  transition: all 0.2s;
  --box-shadow2: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
  margin: auto;
  cursor: pointer;

}


.send.gallery {
  font-size: 0.8rem;
}

.send.gallery {
  animation: none;
  transform: none;
  transition: none;
}



.send .text {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
  margin: 2px;
}

.send svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.svg-wrapper,
.svg-wrapper-1 {
  display: block;
}

.send:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.send:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.send:hover .text {
  transform: translateX(5em);
}

.send:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}






@media (max-width: 1068px) {
  .accordion-container {
    width: min(100% - 30px, 854px);
    margin-top: 30px !important;
  }


  h3 {
    font-size: 17px !important;
    margin-left: 0 !important;
  }


  .panel {
    padding: 0 !important;
    width: 100% !important;
    margin-left: 0;
  }

  .accordion:before {
    margin-right: 0 !important;
  }


}





.accordion-container {

  display: flex;
  flex-direction: column;
  width: min(100% - 30px, 984px);

  justify-content: space-evenly;
  margin: auto;
  margin-top: 70px;
}


.accordion,
.fluidwrap {
  background-color: transparent;
  color: #202f46;
  cursor: pointer;

  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  margin-bottom: 15px;
}

.accordion h3,
.fluidwrap {

  font-size: 19px;
  text-transform: uppercase;
  margin-left: 25px
}

.delivery {

  line-height: 1.4em;

}


.active,
.accordion:hover,
.fluidwrap:hover,
.panelfluid p:hover {
  color: var(--theme-color);
}

.panel {

  background-color: white;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin-left: 26px;
}



.panel p {
  padding-bottom: 20px;
  padding-top: 1px;
  font-size: 16px;

}

.accordion:before {
  content: "\002B";
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-font-color);
  float: right;
  margin-right: 15px;
}



.accordion.active:before {
  content: "\2212";
  color: var(--theme-font-color);
}




.fluid {
  display: inline;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
  background-color: transparent;
}

.panelfluid {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.panelfluid ul {
  list-style-type: none;
  display: flex;

}


.panelfluid ul li {

  margin: 5px 0;
  border-right: 1px solid #000;
}

.panelfluid ul li:last-child {
  border-right: none;
}

.panelfluid p {
  margin-right: 20px;
  padding-top: 1px;
  font-size: 15px;
  margin-left: 20px
}

.socialfoot {
  margin-top: 20px;
}

.fsoc {
  display: inline-flex;
  margin: 15px 20px 0 20px;

}




footer {

  margin-top: 0px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
  background-color: var(--theme-bg-color);
  color: var(--theme-font-color);

}

.footfoter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.instagram {

  width: auto;
  justify-content: center;
}

.mail {

  display: flex;
  text-align: center;
  margin: 10px 0px;
  justify-content: center;
}

.instafoot-mail {
  width: 35px;
  height: 41px;
  margin-left: 18px;
  margin-bottom: -6px;
}


.gmail {
  display: block;
  text-align: center;
  justify-content: center;
  margin-left: 20px;
  margin-top: 7px;
}


.instafoot {
  display: inline-block;
  margin-top: 15px;
  width: 30px;
  height: 30px;
  margin-left: 18px;

}

.instafoot-pinetr {
  margin-right: -10px;
  width: 30px;
}


.instafoot-mail:hover,
.instafoot:hover {
  opacity: 0.7;

}




.logo-footjmm {
  width: 100px;
  margin-left: 20px;

}

.footblock {
  margin: auto;
  margin-top: 0;
}





.page404 {}


.wrapwrap {
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 220px;

  margin-top: 40px;
}

.clumsy {
  display: flex;
  padding: 100px 50px 20px 100px;
  ;
}

.clumsy img {
  width: 500px;
  height: 500px;
}



.notfound {
  gap: 10px;

  padding-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.notfoundtext {
  font-size: 37px;
  color: #61045F;
  font-weight: 900;

}

.notfound .worries {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 400;
}

.notfound p {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3em;
}

.star p {
  font-size: 16px;
}

.star {
 
  position: absolute;
  bottom: 15px;
  left: 30px;
  background-color: none;
}

.notfound a {
  font-size: 20px;
  font-weight: bold;
  color: #61045F;
}

.notfound a:hover {
  opacity: 0.8;
}

.page404 .learn-more {

  border-radius: 2px;
  margin: 30px auto;


}

.page404 .learn-more:hover {
  opacity: 1;
}


@media only screen and (max-width: 1300px) {

  .wrapwrap {
    padding: 0 0;
    margin-top: 40px;

  }

  .clumsy {
    padding: 58px 50px 20px 50px;
  }

  .clumsy img {
    width: 400px;
    height: 400px;
  }
}


@media only screen and (max-width: 300px) {

  .clumsy img {
    height: 25vh;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }

  .wrapwrap {
    margin-top: 10px !important;
  }



}




@media only screen and (max-width: 1000px) {

  .logo-jmm {
    margin-bottom: 0px;
  }

  .hero h1 {
    top: 112px;
    /* margin-left: 0; */
  }

  .wrapwrap {
    flex-direction: column;
    justify-content: initial;
    margin-top: 40px;
  }

  .notfound {
    padding: 0 50px 0 50px;
    text-align: center;
    gap: 5px;
    margin: 0 auto;

  }

  .clumsy {

    padding: 0 0 0 0;
    margin: 0 auto;
  }

  .clumsy img {
    width: 450px;
    height: 400px;
    margin: 0px auto 0px auto;

  }


  .notfound p,
  .notfound a {
    font-size: 18px;
  }

  .notfound .worries {
    font-size: 15px;
  }

  .star p {
    font-size: 14px;
  }

  .notfoundtext {
    font-size: 28px;
  }


  .star {
    padding: 0 5px;
    left: 15px;
  }

  .page404 .learn-more {
    display: block;
    margin: 40px auto 20px auto;
    width: 7.4rem;
  }

}


@media only screen and (max-width: 768px) {



  .wrapwrap {
    padding: 0 0;
  }

  .clumsy {
    margin: 0 auto;

  }

  .clumsy img {
    width: 80vw;
    height: 30vh;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .notfound .worries {
    font-size: 11px;
  }

  .notfound {
    gap: 0;
  }

  .notfound p,
  .notfound a {
    font-size: 13px;

  }

  .star {
    left: 5px;
  }

  .star p {
    font-size: 10px;
  }

  .page404 .notfoundtext {
    font-size: 25px;
    margin: 0 0 1em 0 !important;
  }

  .page404 .learn-more {
    width: 6.5rem;
    margin: 30px auto 20px auto
  }



  .page404 .learn-more .circle {
    width: 1.8rem;
    height: 1.7rem;
  }

  .page404 .learn-more .circle .icon.arrow {
    width: 0.7rem;
    left: 0.37rem;
  }

  .page404 .learn-more .button-text {
    margin: -0.3rem 0 0 1.85rem;
  }



}


.close {
  top: 0;
  right: 0;
  padding: 20px;
  position: absolute;
  z-index: 1;
  border: none;

  background: transparent;
}

.close:hover::before {

  opacity: 1;
}

.close:hover:before {
  transform: none;
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22%230f0f0f%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20transform%3D%22translate%28-290.000000,%20-65.000000%29%22%20fill-rule%3D%22nonzero%22%3E%3Cg%20transform%3D%22translate%28290.000000,%2065.000000%29%22%3E%3Cpath%20d%3D%22M22.5%201.5v21H1.5V1.5h21z%22%20stroke%3D%22%230f0f0f%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M16.999954%208.44117617%2015.5588332%207.00005699%2011.998935%2010.5575%208.44121825%207%207.00004599%208.44117067%2010.5585606%2011.9999973%207%2015.5587778%208.44122375%2017%2011.998935%2013.4414323%2015.5588277%2016.999943%2017%2015.5587723%2013.4414339%2011.9999973z%22/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
}

.close:before {
  background-image: url(data:image/svg+xml;utf8,%3Csvg%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20transform%3D%22translate%28-290.000000,%20-65.000000%29%22%20fill-rule%3D%22nonzero%22%3E%3Cg%20transform%3D%22translate%28290.000000,%2065.000000%29%22%3E%3Cpath%20d%3D%22M22.5%201.5v21H1.5V1.5h21z%22%20stroke%3D%22%230f0f0f%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%230f0f0f%22%20d%3D%22M16.999954%208.44117617%2015.5588332%207.00005699%2011.998935%2010.5575%208.44121825%207%207.00004599%208.44117067%2010.5585606%2011.9999973%207%2015.5587778%208.44122375%2017%2011.998935%2013.4414323%2015.5588277%2016.999943%2017%2015.5587723%2013.4414339%2011.9999973z%22/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E);
  opacity: 0.3;
  width: 24px;
  height: 24px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  transform-origin: center center;
  transition: transform 300ms, opacity 200ms;
  content: '';

}

.no-fields .form {
  margin-top: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}



.cookies-banner {

  position: fixed;
  width: 400px;

  left: 0;
  right: 0;
  bottom: 10px;
  margin: 0 auto;
  z-index: 20000000;
  font-size: 16px;
}

.description {

  margin: 0 0 24px;
  white-space: normal;
  word-wrap: break-word;
  word-break: normal;
}

.description p {
  display: inline-block;
  color: #000;
  font-weight: bolder;
}


.content {
  position: relative;
  display: flex;
  flex-direction: column;
}



.button.accept {
  border: 3px solid #000;
  background-color: white;
}

.button.accept:hover {
  background-color: #000;
  color: whitesmoke;
}

.buttonscookie {
  margin: -8px;
  flex-flow: column;
  display: flex;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}


.buttonscookie .button {
  margin: 8px;
  padding: 11px 32px;
  font-weight: 900;
  line-height: 1.55555556;
  border-radius: 0;
  background: white;
}


.button>span {
  display: block;
  word-wrap: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.button>* {
  min-width: 0;
}

.contenttitle {
  text-transform: none;
}

.contenttitle a,
.description a {
  vertical-align: baseline;
  text-decoration: underline;
  font-size: inherit;
}

.description a:hover {
  color: #61045F;

}


.content .no-fields p {
  display: block;
}



.containercookies {
  box-sizing: border-box;
  font-size: 18px;
  line-height: 1.55555556;
  border-radius: 0;
  box-shadow: 0 11px 32px -5px rgba(54, 61, 77, 0.15);
  position: relative;
  overflow: visible;
  padding-top: 70px;
  padding-right: 48px;
  padding-bottom: 48px;
  padding-left: 48px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(15, 15, 15, 1);
  background-image: url("../image/Cookie3.svg");
  

  background-position: bottom 94px right 70px;

}



/*   */

.narrow-container {

  margin-left: 40px;
  width: 80%;

}

.breadcrumbs {
  display: block;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-size: 1.2rem;

}

.list_bread {
  vertical-align: middle;

  display: inline-block;
  width: 100%;
 
}

.breadcrumbs ol {
  list-style: none outside none;
  overflow: hidden;

  display: flex;
 
  font-size: 1rem;
  white-space: normal;
}

.breadcrumbs li:before {
  content: "\F105";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: bold;
  text-decoration: inherit;
  padding: 0 7px;
  font-size: 1rem;
}

.breadcrumbs li:first-child:before {
  display: none;
}

.breadcrumbs ol li a:hover {
  color: #61045F;
}

.subcm {
  font-size: 19px;
  text-transform: uppercase;
 
}

.subsub {
  line-height: 1.3rem;
  font-size: 15px;
  padding: 10px 0px;
}

.textnotice {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 30px;

}


.textnotice h2 {
  font-size: 30px;
}

.provisions {
  margin-bottom: 50px;
}

@media only screen and (min-width: 960px) {

  .cookies-banner {
    left: auto;
    right: 90px;
    bottom: 32px;
    margin: 0;
  }


  .containercookies {
    box-shadow: 0 10px 24px 0 rgba(54, 61, 77, 0.15);

  }

}

@media only screen and (max-width: 1000px) {


  html.menu-open {
    overflow-y: hidden;
  }

  #hamburger-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    /* display: none; */

  }

  #hamburger-menu.open {
    display: block;
  }

  #hamburger-menu.active {

    display: flex;

  }


  .burger {
    display: block;
    margin-bottom: 4px;
    margin-right: 20px;
    transition: .3s ease-in-out;
  }

  .burger.active .line:first-child {
    transform: translateY(-300px);
  }

  .burger.active .line:nth-child(2) {
    transform: rotate(-405deg) translateY(7.5px);
  }

  .burger.active .line:nth-child(3) {
    transform: rotate(-315deg) translateY(-7.5px);
  }

  .burger.active {
    margin-bottom: 16px;
    position: fixed;
    z-index: 999999;
    margin-bottom: 16px;
    right: 10px;
  }


  .instaNav img {
    margin: auto;

  }

  .nav ul {
    /* display: none; */
    position: absolute;
    right: 0;
    top: 8vh;
    z-index: 9999;
    height: 92vh;
    flex-direction: column;
    text-align: center;
    transform: translate(100%);
    transition: .3s ease-in-out;
    background: #f6f6f6;
    width: 100%;
    justify-content: flex-start;
    gap: 50px;
    margin-right: 0px;
    margin-top: -1px;

  }

  .nav ul li {
    padding: 0;
    margin: 0;
  }

  .nav ul.active {
    transform: translate(0%);
    display: flex;

  }

  .nav ul li:first-child {
    margin-top: 120px;
  }

  .nav a {
    font-weight: bold;
    font-size: 2.5rem;
    letter-spacing: 2px;
  }

}

@media only screen and (max-width: 768px) {





  ul.nav {
    width: 100%;
  }

  .nav {

    width: 100%;
  }

  .cookies-banner {
    font-size: 14px
  }

  .containercookies {
    padding-top: 70px;
  }

}



@media only screen and (max-width:1000px) {
  .narrow-container {
    margin-left: 10px;
    width: 95%
  }



  .hero {
    margin-bottom: 50px;

  }

  .topJMM {
    display: none;
  }

  .top.show {
    display: none
  }





  .section-tittle {

    text-align: center;
    margin: 80px 30px 100px 20px;
  }

  .panel {

    margin-left: 0;
  }

 



  .gallery-work {

    flex-flow: column;

    margin-bottom: 40px;
    width: 100%;
    height: min-content;

  }

  .gallery-work .gscribe {
    display: block;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin: auto;
    background-color: var(--theme-bg-color);
    padding: 15px;

  }

  .gallery-work.containerform .gscribe {
    display: block;
    width: 100%;
    justify-content: center;
    text-align: center;

    margin: 10px auto;
  }

  .gallery-work .gscribeR {
    display: block;
    justify-content: center;
    text-align: center;
    margin: auto;
    height: max-content;
    width: 100%;
    background-color: var(--theme-bg-color);
    padding: 15px;
  }

  .gallery-work .gwork {

    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: var(--theme-bg-color);

  }


  .gallery-work.containerform {
    display: flex;
    padding: 0 0;

  }

  .gallery-work.containerform .gwork {

    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: max-content;
    margin: 10px;
    background-color: var(--theme-bg-color);

  }




  .gallery-work.containerform .gwork img {

    width: 100%;
    height: 400px;
    opacity: 0.8;
  }

  .contacktright {
    width: 100%;

  }

  .gallery-work .gwork img {
    width: 100%;


  }

  .gallery-work:not(.gowrk) .gwork:hover {

    transform: none;
  }



  .gallery-work.right {
    margin-bottom: 40px;
    margin-left: 0;
    flex-direction: column;
  }

  .gallery-work.right .gwork {}

  .gallery-work.containerform .gwork img {
    display: none;
  }


  .glide__arrows {
    display: none;

  }

  .top {
    display: none;
  }

  .top::after {
    display: none;
  }
}


@media only screen and (max-width: 992px) {
  .hero h1 {

    margin-left: 0;
  }
}

@media only screen and (max-width: 768px) {




  .glide__bullets.glide__bullets {}

  .title-main {
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .title-sub {
    padding: 0px;

  }

 

  .fluid {}

  .gallery-work .gwork img {

    width: 100%;



  }


  .shop main {
    margin-top: 50px;
  }



  .shop.gallery-work.slideshow .gscribe,
  .shop.gallery-work.slideshow .gscribeR {

    width: 100% !important;

  }

  .gallery-work .gscribe,
  .gallery-work .gscribeR {
    width: 100%;


  }


  .goshop {
    font-size: 2.5vw;
    width: 20vw;
  }

  .goshop::before {
    width: 6vw;
    height: 3vh;
    left: calc(100% - 3.8vw);
    top: calc(100% - 2.4vh);
  }

  .gallery-work.containerform .gwork img {
    display: none;
  }

  .gallery-work.containerform .gscribe {


    left: 0px;
    width: 100%;

  }




  .blog-post {
    margin-top: 80px;
    width: 100%;
  }


  .blog-post_img {
    width: 100%;
  }

  .containerform:after {
    display: none;
  }

  .containerform {
    margin: 30px 0px;
    position: static;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px 0px;
  }

  .gallery-work.containerform .gwork {


    width: 100%;

  }

  .gallery-work .gwork {
    box-shadow: none;
  }
}


@media only screen and (max-width: 500px) {


  .section-tittle {
    /* text-align: center; */
  }
}



#cadmium_green {
  object-position: 0% 0%;

}

.meme {
  margin-top: 20px;
  margin-left: 30px;
}
